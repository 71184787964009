import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import PropTypes from 'prop-types';

import { Container, Header, Link } from './styles';

export default function Whatsapp({ status }) {
  return (
    <Container status={status}>
      <Header>Atendimento por WhatsApp!</Header>

      <div className="linkList">
        <Link
          href="https://wa.me/5511947441001?text=Olá, gostaria de saber mais sobre as scooters"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          <FaWhatsapp />
          Fale Agora com o Consultor
        </Link>
      </div>
    </Container>
  );
}

Whatsapp.propTypes = {
  status: PropTypes.bool.isRequired,
};

Whatsapp.propDefault = {
  status: false,
};
