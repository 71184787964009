import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

import { Container, ImageContainer } from "./styles";

export default function ImageGallery({ modelName }) {
  const images = useStaticQuery(graphql`
    fragment modelImage on File {
      childImageSharp {
        fluid(maxWidth: 600, quality: 60) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      p1r: allFile(
        filter: { relativeDirectory: { eq: "models/galleries/p1r" } }
      ) {
        edges {
          node {
            ...modelImage
          }
        }
      }

      p2r: allFile(
        filter: { relativeDirectory: { eq: "models/galleries/p2r" } }
      ) {
        edges {
          node {
            ...modelImage
          }
        }
      }

      p6r: allFile(
        filter: { relativeDirectory: { eq: "models/galleries/p6r" } }
      ) {
        edges {
          node {
            ...modelImage
          }
        }
      }

      p6iii: allFile(
        filter: { relativeDirectory: { eq: "models/galleries/p6iii" } }
      ) {
        edges {
          node {
            ...modelImage
          }
        }
      }
    }
  `);

  function gallery() {
    const galleryImages = images[modelName];

    if (typeof galleryImages !== "undefined") {
      return galleryImages.edges.map((image) => (
        <ImageContainer>
          <Img
            style={{ height: "100%" }}
            fluid={image.node.childImageSharp.fluid}
          />
        </ImageContainer>
      ));
    }

    return null;
  }

  return <Container>{gallery()}</Container>;
}

ImageGallery.propTypes = {
  modelName: PropTypes.string.isRequired,
};
