export default [
  {
    id: 1,
    model: "p1r",
    name: "Chopper Elétrica P1-R",
    potency: "2500w",
    velocity: "65 km/h",
    autonomy: "60 km",
    load: "200 kg",
    climb: "35 graus",
    breaks: "Disco dianteiro/traseiro",
    battery: "lithium",
    voltage: "60 v",
    amperage: "23A",
    charge: "8 horas",
    warranty: "6 meses (180 dias)",
    price: "14.500,00",
  },
  {
    id: 2,
    model: "p2r",
    name: "Patinete Elétrico P2-R",
    potency: "1500W",
    velocity: "45 km/h",
    autonomy: "Até 40 km",
    load: "150 kg",
    climb: "25 graus",
    breaks: "Disco dianteiro/traseiro",
    battery: "lithium",
    voltage: "60 v",
    amperage: "15A",
    charge: "8 horas",
    warranty: "6 meses (180 dias)",
    price: "9.000,00",
  },
  {
    id: 3,
    model: "p6r",
    name: "Patinete Elétrico P6-R",
    potency: "2000w",
    velocity: "55 km/h",
    autonomy: "Até 50 km",
    load: "200 kg",
    climb: "35 graus",
    breaks: "Disco dianteiro/traseiro",
    battery: "lithium",
    voltage: "60 v",
    amperage: "23A",
    charge: "8 horas",
    warranty: "6 meses (180 dias)",
    price: "12.000,00",
  },
  {
    id: 4,
    model: "p6iii",
    name: "Triciclo Elétrico P6III",
    potency: "2500w",
    velocity: "40 km/h",
    autonomy: "Até 35 km",
    load: "200 kg",
    climb: "35 graus",
    breaks: "à disco",
    battery: "lithium",
    voltage: "60v",
    amperage: "21A",
    charge: "8 horas",
    warranty: "6 meses (180 dias)",
    price: "15.000,00",
  },
];
