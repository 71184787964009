import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import NumbersMenu from './NumbersMenu';
import {
  Container, Button, Shadow, Counter,
} from './styles';

export default function Whatsapp() {
  const [status, setStatus] = useState(false);

  return (
    <Container>
      <Button onClick={() => setStatus(!status)}>
        <FaWhatsapp />
        <Counter>1</Counter>
      </Button>
      <Shadow />
      <NumbersMenu status={status} />
    </Container>
  );
}
