import React from 'react';

import { Container, H1 } from './styles';

export default function Title() {
  return (
    <Container>
      <H1>
        Contato
      </H1>
    </Container>
  );
}
