import React from 'react';

import Separation from '~/components/Separation';

import ImageGallery from '../ImageGallery';
import ModelImage from '../ModelImage';
import modelData from '../models';
import WhatsappButton from '../WhatsappButton';
import {
  Container,
  Model,
  Title,
  Image,
  Columns,
  Price,
  FeatureList,
  Feature,
} from './styles';

export default function ModelDetail() {
  const modelList = modelData.map((model) => (
    <Model key={model.id}>
      <Title>
        {model.name} | {model.potency}
      </Title>
      <Columns>
        <div>
          <Image>
            <ModelImage imageName={model.model} />
          </Image>

          <Price>
            <span className="value">
              R$ <strong>{model.price}</strong>
            </span>
            <span className="condition">em até 10x sem juros!</span>
          </Price>
        </div>

        <div>
          <FeatureList>
            <Feature>
              Potência: <strong>{model.potency}</strong>
            </Feature>
            <Feature>
              Velocidade Máxima: <strong>{model.velocity}</strong>
            </Feature>
            <Feature>
              Autonomia: <strong>{model.autonomy}</strong>
            </Feature>
            <Feature>
              Carga: <strong>{model.load}</strong>
            </Feature>
            <Feature>
              Escalada: <strong>{model.climb}</strong>
            </Feature>
            <Feature>
              Freios: <strong>{model.breaks}</strong>
            </Feature>
            <Feature>
              Bateria: <strong>{model.battery}</strong>
            </Feature>
            <Feature>
              Voltagem: <strong>{model.voltage}</strong>
            </Feature>
            <Feature>
              Amperagem: <strong>{model.amperage}</strong>
            </Feature>
            <Feature>
              Carregamento: <strong>{model.charge}</strong>
            </Feature>
            <Feature>
              Garantia: <strong>{model.warranty}</strong>
            </Feature>
          </FeatureList>

          <WhatsappButton />
        </div>
      </Columns>
      <ImageGallery modelName={model.model} />
      <Separation />
    </Model>
  ));

  return <Container>{modelList}</Container>;
}
