import React from 'react';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';

import Feature from './Feature';
import featureData from './features';
import { Wrapper, FeatureList } from './styles';

export default function Features() {
  const features = featureData.map((feature) => (
    <Feature key={feature.title} icon={feature.icon} title={feature.title} text={feature.text} />
  ));

  return (
    <Wrapper>
      <Container>
        <Columns>
          <Columns.Column>
            <FeatureList>{features}</FeatureList>
          </Columns.Column>
        </Columns>
      </Container>
    </Wrapper>
  );
}
