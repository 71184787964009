export default [
  {
    icon: "eletrico",
    title: "Elétrico",
    text: "Zero emissão de gás carbônico",
  },
  {
    icon: "autonomia",
    title: "Mais Autonomia",
    text: "Carga com autonomia de 30 a 40km",
  },
  {
    icon: "entrega",
    title: "Pronta Entrega",
    text: "Compre e saia da loja pronto para usar",
  },
  {
    icon: "garantia",
    title: "Garantia",
    text: "Garantia de 6 meses",
  },
  {
    icon: "oficina",
    title: "Oficina Própria",
    text: "Amplo estoque de peças e acessórios",
  },
];
