import styled from 'styled-components';

import colors from '~/scss/variables';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 9rem;

  p {
    color: ${colors.cGray3};
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.1rem;
    text-align: center;
  }

  @media (max-width: 425px) {
    padding: 0 2rem;
  }
`;

export const Content = styled.div`
  width: 66.5rem;

  @media (max-width: 425px) {
    width: 100%;
  }
`;
