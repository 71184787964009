import styled, { css } from 'styled-components';

import colors from '~/scss/variables';

const basePseudo = css`
  background-image: url('/contact-title-pseudo-bg.png');
  content: '';
  display: inline-block;
  height: 3.4rem;
  position: absolute;
  width: 46.5rem;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4.3rem;
`;

export const H1 = styled.h1`
  align-items: center;
  display: flex;
  color: ${colors.cGreen1};
  font-size: 4.8rem;
  font-weight: 700;
  position: relative;

  @media (min-width: 426px) {
    &::before {
      ${basePseudo}
      right: 100%;
      margin-right: 2rem;
    }

    &::after {
      ${basePseudo}
      left: 100%;
      margin-left: 2rem;
    }
  }
`;
