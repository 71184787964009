import styled from 'styled-components';

import colors from '~/scss/variables';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 425px) {
    padding: 0 2rem;
  }
`;

export const Model = styled.div`
  margin-bottom: 5rem;
  width: 100%;

  @media (max-width: 425px) {
    margin-bottom: 3rem;
  }
`;

export const Title = styled.h2`
  color: black;
  font-size: 4.8rem;
  font-weight: 700;
  margin-bottom: 9rem;
  text-align: center;

  @media (max-width: 425px) {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
`;

export const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 13.8rem;

  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`;

export const Image = styled.div`
  height: 48rem;
  margin-bottom: 1rem;

  @media (max-width: 425px) {
    height: 25rem;
  }
`;

export const Price = styled.div`
  align-items: center;
  border: 0.1rem solid ${colors.cGray3};
  display: flex;
  justify-content: center;
  padding: 2rem 0;

  .value {
    color: ${colors.cGreen2};
    font-size: 2.6rem;
    font-weight: 300;
    font-style: italic;
    margin-right: 3rem;
    text-transform: uppercase;

    strong {
      color: ${colors.cGreen1};
      font-size: 5rem;
      font-weight: 900;
      font-style: normal;
    }
  }

  .condition {
    color: ${colors.cPink1};
    font-size: 1.8rem;
    font-weight: 600;
    font-style: italic;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    margin-bottom: 2rem;

    .value {
      font-size: 2rem;

      strong {
        font-size: 3.5rem;
      }
    }

    .condition {
      font-size: 1.8rem;
    }
  }
`;

export const FeatureList = styled.ul`
  margin-bottom: 2rem;
`;

export const Feature = styled.li`
  color: ${colors.cGray3};
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.1rem;
  text-transform: uppercase;

  strong {
    color: ${colors.cGray3};
    font-weight: 600;
    margin-left: 2rem;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @media (max-width: 425px) {
    font-size: 1.6rem;
    line-height: 1rem;
    text-align: center;

    strong {
      margin-left: 1rem;
    }
  }
`;

export const Highlight = styled.h3`
  color: ${colors.cYellow1};
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-transform: uppercase;

  @media (max-width: 425px) {
    font-size: 2.5rem;
    margin-bottom: 4rem;
    text-align: center;
  }
`;
