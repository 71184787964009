import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import { Container } from './styles';

export default function WhatsappButton() {
  return (
    <Container
      href="https://wa.me/11947441001"
      rel="noreferrer noopener"
      target="_blank"
    >
      <FaWhatsapp />
      Fale conosco pelo WhatsApp
    </Container>
  );
}
