import React from 'react';

import Proptypes from 'prop-types';

import {
  Container, Icon, Title, Text,
} from './styles';

export default function Feature({ icon, title, text }) {
  return (
    <Container>
      <Icon>
        <img src={`/icons/icon-${icon}.svg`} alt={title} />
      </Icon>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Container>
  );
}

Feature.propTypes = {
  icon: Proptypes.string.isRequired,
  title: Proptypes.string.isRequired,
  text: Proptypes.string.isRequired,
};
