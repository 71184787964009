import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import PropTypes from "prop-types";

export default function ModelImage({ imageName }) {
  const images = useStaticQuery(graphql`
    fragment modelImage on File {
      childImageSharp {
        fluid(maxWidth: 600, quality: 60) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      p1r: file(relativePath: { eq: "models/p1r.jpg" }) {
        ...modelImage
      }

      p2r: file(relativePath: { eq: "models/p2r.jpg" }) {
        ...modelImage
      }

      p6r: file(relativePath: { eq: "models/p6r.jpg" }) {
        ...modelImage
      }

      p6iii: file(relativePath: { eq: "models/p6iii.jpg" }) {
        ...modelImage
      }
    }
  `);

  return (
    <Img
      style={{ height: "100%" }}
      objectFit="contain"
      fluid={images[imageName].childImageSharp.fluid}
    />
  );
}

ModelImage.propTypes = {
  imageName: PropTypes.string.isRequired,
};
