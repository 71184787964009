import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 5rem;
`;

export const FeatureList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 2.4rem;
  row-gap: 2rem;

  @media (max-width: 425px) {
    grid-template-columns: 1fr 1fr;
  }
`;
