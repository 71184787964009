import React, { useState } from "react";

import { Container, Input, TextArea, Button } from "./styles";

export default function Form() {
  const [formData, setFormData] = useState({});

  const inputData = [
    {
      name: "name",
      label: "Nome",
      placeholder: "Insira seu nome",
    },
    {
      name: "email",
      type: "email",
      label: "E-mail",
      placeholder: "Insira seu e-mail",
    },
    {
      name: "phone",
      label: "Telefone",
      placeholder: "Insira seu telefone",
    },
  ];

  function changeFormData(event, fieldName) {
    setFormData({ ...formData, [fieldName]: event.target.value });
  }

  function submitForm(event) {
    event.preventDefault();
  }

  const inputs = inputData.map((input) => (
    <>
      <label htmlFor={input.name}>{input.label}</label>
      <Input
        id={input.name}
        type={input.type || "text"}
        onChange={(event) => changeFormData(event, input.name)}
        placeholder={input.placeholder}
        required
      />
    </>
  ));

  return (
    <Container onSubmit={(event) => submitForm(event)}>
      {inputs}

      <label htmlFor="message">Mensagem</label>
      <TextArea
        id="message"
        placeholder="Sua mensagem"
        onChange={(event) => changeFormData(event, "message")}
        rows={6}
        required
      />

      <Button type="submit">Enviar</Button>
    </Container>
  );
}
