import styled from 'styled-components';

import colors from '~/scss/variables';

export const Container = styled.div`
  background-image: linear-gradient(to right, white 0%, ${colors.cGray1} 50%, white 100%);
  height: .2rem;
  margin-bottom: ${(props) => props.margin ?? 5}rem;
  width: 100%;
`;
