import styled from 'styled-components';

import colors from '~/scss/variables';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 2.4rem;
  row-gap: 2rem;
  margin: 3rem 0 6rem 0;
  width: 100%;

  @media (max-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ImageContainer = styled.a`
  box-shadow: 0 0.3rem 0.6rem ${colors.cGray2};
  height: 17rem;
`;
