import styled, { css } from 'styled-components';

import colors from '~/scss/variables';

const basePseudo = css`
  background-image: url('/model-title-pseudo-bg.png');
  content: '';
  display: inline-block;
  height: 3.4rem;
  position: absolute;
  width: 35%;
`;

export const Container = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  position: relative;
  text-transform: uppercase;
  width: 100%;

  @media (min-width: 426px) {
    &::before {
      ${basePseudo}
      left: 0;
      top: 3.5rem;
    }

    &::after {
      ${basePseudo}
      bottom: 3.5rem;
      right: 0;
    }
  }
`;

export const TitleBox = styled.div`
  background-color: ${colors.cGreen1};
  padding: 3rem 6rem;
`;

const baseSuTitles = css`
  display: inline-block;
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 2.1rem;
  width: 100%;
`;

export const SuperTitle = styled.span`
  ${baseSuTitles}
`;

export const SubTitle = styled.span`
  ${baseSuTitles}

  @media (min-width: 426px) {
    text-align: right;
  }
`;

export const TitleTag = styled.h1`
  color: white;
  font-size: 4.8rem;
  font-weight: 500;
  line-height: 7rem;

  strong {
    color: inherit;
    font-weight: 700;
  }
`;
