import React from 'react';

import ModelImage from '../ModelImage';
import modelData from '../models';
import { Container, Model, ModelTitle } from './styles';


export default function ModelDisplay() {
  const models = modelData.map((model) => (
    <Model key={model.id}>
      <ModelTitle>{model.name}</ModelTitle>
      <div className="image-container">
        <ModelImage imageName={model.model} />
      </div>
    </Model>
  ));

  return <Container>{models}</Container>;
}
