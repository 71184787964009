import styled, { keyframes } from 'styled-components';

const green1 = '#25d366';
const green2 = '#52b43e';

export const Container = styled.div`
  bottom: 3rem;
  position: fixed;
  right: 3rem;
  z-index: 999;
`;

export const buttonAnimation = keyframes`
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(1rem);
  }
`;

export const Button = styled.a`
  align-items: center;
  background-color: ${green1};
  background-image: linear-gradient(to bottom, ${green2} 0%, ${green2} 100%);
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  height: 6rem;
  position: relative;
  width: 6rem;
  z-index: 1;

  animation: ${buttonAnimation} 1s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;

  &:hover {
    color: white;
  }

  svg {
    font-size: 3.5rem;
  }
`;

export const shadowAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: .3;
  }

  100% {
    transform: scale(0.8);
    opacity: .5;
  }
`;

export const Shadow = styled.div`
  background-color: #aaa;
  border-radius: 50%;
  height: 1.5rem;
  width: 6rem;

  animation: ${shadowAnimation} 1s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
`;

export const Counter = styled.div`
  align-items: center;
  background-color: #cc0000;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 800;
  height: 2rem;
  position: absolute;
  width: 2rem;
  z-index: 20;

  right: 0;
  top: 0;
`;
