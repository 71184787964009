import React from 'react';
import { Container, Columns } from 'react-bulma-components';

import Separation from '../Separation';
import ModelDetail from './ModelDetail';
import ModelDisplay from './ModelDisplay';
import { Wrapper } from './styles';
import Title from './Title';

export default function Models() {
  return (
    <Wrapper>
      <Container>
        <Columns>
          <Columns.Column>
            <Title />
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <ModelDisplay />
          </Columns.Column>
        </Columns>
        <Separation />
        <Columns>
          <Columns.Column>
            <ModelDetail />
          </Columns.Column>
        </Columns>
      </Container>
    </Wrapper>
  );
}
