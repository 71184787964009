import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Container } from './styles';

export default function TestDrive() {
  const image = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "banner-test-drive.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      mobile: file(relativePath: { eq: "banner-test-drive-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const sources = [
    image.mobile.childImageSharp.fluid,
    {
      ...image.desktop.childImageSharp.fluid,
      media: '(min-width: 426px)',
    },
  ];

  return (
    <Container>
      <Img style={{ height: '100%' }} fluid={sources} />
    </Container>
  );
}
