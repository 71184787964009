import { darken } from 'polished';
import styled from 'styled-components';

import colors from '~/scss/variables';


export const Container = styled.a`
  background-color: #42cb6d;
  border-radius: 3rem;
  box-shadow: 0 0.3rem 0 ${colors.cGreen1};
  color: white;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 900;
  padding: 1.5rem 1.7rem 1.5rem 6rem;
  position: relative;
  text-transform: uppercase;
  transition: .2s ease all;

  svg {
    color: white;
    font-size: 3.6rem;
    left: 1.7rem;
    top: .8rem;
    position: absolute;
  }

  &:hover {
    color: white;
    background-color: ${darken(0.10, '#42cb6d')};
  }
`;
