import React from 'react';
import { Container, Columns } from 'react-bulma-components';
import { FaMapMarkerAlt, FaPhone, FaWhatsapp } from 'react-icons/fa';

import Logo from '~/images/header-logo.svg';

import { ContactInfo, ContactItem, MapContainer } from './styles';

export default function Location() {
  return (
    <Container>
      <Columns>
        <ContactInfo size="one-third">
          <Logo />
          <ContactItem>
            <FaMapMarkerAlt />
            Rua dos Trilhos, 1886 <br />
            Mooca - São Paulo/SP
          </ContactItem>

          <ContactItem href="tel:+551126033000">
            <FaPhone />
            11 2603-3000
          </ContactItem>

          <ContactItem href="https://wa.me/5511947441001">
            <FaWhatsapp />
            11 94744-1001
          </ContactItem>
          <img src="gloov.png" alt="Gloov" className="gloov" />
        </ContactInfo>
        <Columns.Column>
          <MapContainer>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.3643126440807!2d-46.59412188454665!3d-23.555355684685757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5eb4ef726841%3A0x5f36868092fd7e8b!2sReavel%20Multimarcas!5e0!3m2!1spt-BR!2sbr!4v1583363879836!5m2!1spt-BR!2sbr"
              width="600"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
            />
          </MapContainer>
        </Columns.Column>
      </Columns>
    </Container>
  );
}
