import Columns from 'react-bulma-components/lib/components/columns';

import styled from 'styled-components';

import colors from '~/scss/variables';

export const ContactInfo = styled(Columns.Column)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  svg {
    margin-bottom: 4.4rem;
  }

  .gloov {
    height: 7rem;
  }
`;

export const ContactItem = styled.a`
  display: flex;
  color: ${colors.cGray3};
  font-size: 1.6rem;
  font-weight: 500;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  svg {
    color: ${colors.cPink1};
    font-size: 2rem;
    margin: 0 1rem 0 0;
  }
`;

export const MapContainer = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;
