import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2.4rem;

  @media (max-width: 425px) {
    flex-direction: column;
    padding: 0 2rem;
  }
`;

export const Model = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 426px) {
    margin-bottom: 4rem;
    width: calc(50% - 2.4rem);
  }

  @media (max-width: 425px) {
    margin-bottom: 2rem;
  }

  &:not(:last-child) {
    margin-right: 2.4rem;
  }

  .image-container {
    height: 30rem;
  }
`;

export const ModelTitle = styled.h2`
  color: black;
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 3rem;
  text-align: center;
  text-transform: uppercase;
`;
