import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import { Container } from './styles';

export default function Banner() {
  const data = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      mobileImage: file(relativePath: { eq: "banner-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 425, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 761px)',
    },
  ];

  return (
    <Container>
      <picture>
        <Img style={{ height: '100%' }} fluid={sources} />
      </picture>
    </Container>
  );
}
