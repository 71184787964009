import styled from 'styled-components';

export const Container = styled.section`
  height: 70rem;
  margin-bottom: 7rem;

  picture {
    display: block;
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
    }
  }

  @media (max-width: 1215px) {
    height: 60rem;
  }

  @media (max-width: 1000px) {
    height: 55rem;

    img {
      object-fit: contain;
    }
  }

  @media (max-width: 760px) {
    height: 60rem;
  }

  @media (max-width: 425px) {
    height: 25rem;
    margin-bottom: 3rem;
  }
`;
