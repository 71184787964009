import React from 'react';

import {
  Container, TitleBox, SuperTitle, TitleTag, SubTitle,
} from './styles';

export default function Title() {
  return (
    <Container>
      <TitleBox>
        <SuperTitle>Encontre o</SuperTitle>
        <TitleTag>Modelo <strong>Ideal</strong></TitleTag>
        <SubTitle>Para Você</SubTitle>
      </TitleBox>
    </Container>
  );
}
