import React from 'react';

import Banner from '~/components/Banner';
import Contact from '~/components/Contact';
import Features from '~/components/Features';
import Layout from '~/components/layout';
import Location from '~/components/Location';
import Models from '~/components/Models';
import SEO from '~/components/seo';
import Separation from '~/components/Separation';
import TestDrive from '~/components/TestDrive';
import Whatsapp from '~/components/Whatsapp';

const IndexPage = () => (
  <Layout>
    <SEO title="Scooters, Motos e Patinetes Elétricos" />
    <Banner />
    <Features />
    <Separation />
    <Models />
    <TestDrive />
    <Contact />
    <Separation />
    <Location />
    <Whatsapp />
  </Layout>
);

export default IndexPage;
