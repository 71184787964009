import styled from "styled-components";

import colors from "~/scss/variables";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Icon = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 12rem;
  height: 12rem;
  box-shadow: 0 0.3rem 0.6rem ${colors.cGray2};
  background-color: white;
  border-radius: 50%;
  margin-bottom: 1.5rem;

  @media (max-width: 425px) {
    width: 9rem;
    height: 9rem;
  }

  img {
    object-fit: contain;
    height: 70%;
    width: 70%;
  }
`;

export const Title = styled.h2`
  color: ${colors.cGreen2};
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 425px) {
    font-size: 1.5rem;
  }
`;

export const Text = styled.p`
  color: ${colors.cGray3};
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
`;
