import styled from 'styled-components';

export const Container = styled.div`
  height: 25rem;
  margin-bottom: 12rem;
  width: 100%;

  @media (max-width: 425px) {
    display: none;
  }
`;
