import React from 'react';

import Form from './Form';
import { Container, Content } from './styles';
import Title from './Title';

export default function Contact() {
  return (
    <Container>
      <Title />
      <Content>
        <p>
          Entre em contato para conhecer melhor nossos modelos e tirar suas
          dúvidas. Um de nossos consultores entrará em contato o quanto antes.
        </p>
        <Form />
      </Content>
    </Container>
  );
}
